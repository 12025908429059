.text {
	display: block;
	margin-bottom: 1.5rem;
	line-height: 1.5;
	font-size: var(--text-font-size, inherit);
	color: var(--text-color, inherit);

	.heading + & {
		margin-top: -0.5rem;
	}

	&.--grid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 1rem;

		&.--align-center {
			justify-content: center;
		}

	}

	&.--icon {
		display: flex;
		gap: 1rem;

		& > svg {
			flex-shrink: 0;
			margin-top: -0.25lh;
			width: 2rem;
			height: 2rem;
			fill: currentColor;
		}

	}

	&.--icon-success > svg { color: var(--color-signal-success); }
	&.--icon-failure > svg { color: var(--color-signal-failure); }

}
