.dialog {

	--dialog-margin-inline: 1rem;
	--dialog-padding-inline: 1.5rem;
	--badge-margin: 1.5rem;

	transition: top 200ms;
	position: var(--dialog-position, absolute);
	inset: var(--dialog-inset, var(--dialog-top, auto) 0 auto auto);
	z-index: var(--dialog-z-index, auto);
	scroll-margin: var(--dialog-scroll-margin, 1.5rem);
	margin: var(--dialog-margin, -0.5rem var(--dialog-margin-inline) 0);
	box-shadow: 0 0.125rem 0.5rem rgb(0 0 0/20%);
	width: 100%;
	max-width: var(--dialog-max-width, 600px);
	background-color: white;
	background-clip: padding-box;
	padding-block: 1.5rem;
	padding-inline: var(--dialog-padding-inline);
	line-height: 1.35;

	&:not(.--modal):not(.--bare)::before {
		clip-path: polygon(50% 0, 0 100%, 100% 100%);
		position: absolute;
		bottom: 100%;
		right: 0;
		margin-inline: 1.25rem;
		width: 1.5rem;
		height: 0.75rem;
		background-color: inherit;
		content: "";
	}

	&::backdrop {
		animation: var(--dialog__backdrop-animation, var(--animation-fade-in));
		background-color: rgb(0 0 0/50%);
	}

	&[data-state~="closing"] {
		animation: var(--animation-slide-out);

		&::backdrop {
			--dialog__backdrop-animation: var(--animation-fade-out);
		}

	}

	&:focus-visible {
		outline: none;
	}

	&.--modal {
		--dialog-position: fixed;
		--dialog-inset: 0;
		--dialog-z-index: 3;
		--dialog-margin: auto;
	}

	@media (width < 720px) {
		--dialog-scroll-margin: 0;
		--dialog-margin-inline: 0;
		--dialog-max-width: none;
	}

	@media (width < 500px) {
		--dialog-padding-inline: 1rem;
	}

}

.dialog__card {

	--card-padding-inline: var(--dialog-padding-inline); /* Tables need this. */

	position: relative;
	margin-top: 2.5rem;
	margin-inline: calc(var(--dialog-padding-inline) * -1);
	background-color: var(--color-theme-10);
	padding-top: 1.5rem;
	padding-inline: var(--dialog-padding-inline);

	&.--overflow::after {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-left: calc(var(--dialog-padding-inline) * -1);
		box-shadow: inset 0 -0.5rem 0.5rem -0.5rem rgb(0 0 0/35%);
		width: calc(100% + (var(--dialog-padding-inline) * 2));
		height: 0.5rem;
		content: "";
	}

}

.dialog__scroller {

	--viewport-scroll-padding-top: 0;

	min-height: var(--wrapper-min-height, none);
	max-height: 50vh;
	overflow-y: scroll;
	overscroll-behavior: contain;
	margin-top: 0.5rem;
	margin-inline: calc(var(--dialog-padding-inline) * -1);
	background-color: var(--color-theme-10);
	padding-inline: var(--dialog-padding-inline);
}

.dialog__canvas {
	position: relative;
	width: 100%;
	aspect-ratio: 2/1;
	margin-bottom: 1rem;
	border-bottom: 1px solid var(--color-theme-30);
	background-color: white;
	padding-bottom: 1rem;

	&:empty::before {
		animation: var(--animation-spin);
		position: absolute;
		inset: 50%;
		margin: -1rem;
		width: 2rem;
		height: 2rem;
		border: 3px solid var(--color-signal-neutral);
		border-top-color: transparent;
		border-radius: 50%;
		content: "";
	}

	& > img {
		animation: var(--animation-fade-in);
		vertical-align: top;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

}

.dialog__footer {
	display: flex;
	justify-content: var(--dialog__footer-justify-content, space-between);
	align-items: center;
	gap: 1rem;
	margin-top: 1.5rem;

	&:has(> :only-child) {
		--dialog__footer-justify-content: flex-end;
	}

}
