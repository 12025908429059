.content {

	--content-padding-inline: 1.5rem;

	position: relative;
	margin-inline: auto;
	width: 100%;
	max-width: var(--content-max-width, 37.5rem);
	padding-top: 1.5rem;
	padding-inline: var(--content-padding-inline);

	@media (width < 720px) {
		--content-max-width: none;
	}

	@media (width < 500px) {
		--content-padding-inline: 1rem;
	}

}
