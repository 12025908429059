input,
select,
textarea {

	--input-height: 2.5rem;

	transition: box-shadow 200ms, border-color 200ms, color 200ms;
	box-shadow: var(--input-box-shadow, none);
	border: 1px solid var(--input-border-color, var(--color-theme-50));
	border-radius: 0.25rem;
	background-color: var(--input-background-color, white);
	font-size: 1rem;
	font-weight: 500;
	color: var(--input-color, var(--color-theme-90));
	cursor: inherit;

	:is(form, .form)[data-state~="invalid"] :is(&:invalid, &.--dummy:invalid + &) {
		--input-border-color: var(--color-signal-failure);
		--input-color: var(--color-signal-failure);
		--input-box-shadow: inset 0 0 0 1px var(--input-border-color);
	}

}

input {
	height: var(--input-height);
	padding-top: var(--text-baseline-offset);
	text-indent: var(--input-text-indent, 0.75rem);

	&[type="checkbox"] {
		appearance: none;
		outline-offset: -1px;
		width: 1.5rem;
		height: 1.5rem;

		&:checked {
			background: white svg-load("../../icons/check.svg", fill=#50571f) center/85% 85% no-repeat;
		}

	}

	&[type="radio"] {
		appearance: none;
		outline-offset: -1px;
		box-shadow: inset 0 0 0 0.2975rem white;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 1.5rem;

		&:checked {
			--input-background-color: var(--color-theme-70);
		}

	}

	&[type="search"] {
		border-radius: var(--input-height);
		background: white svg-load("../../icons/search.svg", fill=#a8aa95) 0.5rem center/1.75rem 1.75rem no-repeat;
		padding-inline: 2.5rem;
		text-indent: 0;

		&.--reset::-webkit-search-cancel-button {
			appearance: none;
			position: absolute;
			top: -1px;
			right: -1px;
			opacity: 1;
			margin: 0.3125rem;
			box-sizing: border-box;
			width: 1.875rem;
			height: 1.875rem;
			border: 2px solid var(--color-theme-50);
			border-radius: 50%;
			background: white svg-load("../../icons/close.svg", fill=#a8aa95) center center/1.375rem 1.375rem no-repeat;
		}
		&:not(.--reset)::-webkit-search-cancel-button {
			display: none;
		}

	}

	&.--dummy {
		position: absolute;
		inset: 0;
		z-index: -1;
		opacity: 0;
		pointer-events: none;
	}

	&.--toggle {

		--input-text-indent: 0;

		background: white svg-load("../../icons/chevron-down.svg", fill=#a8aa95) right 0.5rem center/1.5rem 1.5rem no-repeat;
		padding: var(--text-baseline-offset) 2.5rem 0 0.75rem;
		text-overflow: ellipsis;
		user-select: none;
	}

}

/* Weirdly, these don't work when nested, maybe it's PostCSS/autoprefixer? */
input::placeholder {
	font-size: 90%;
	font-style: italic;
	color: var(--input__placeholder-color, var(--color-theme-50));
}

select {
	appearance: none;
	min-width: 10rem;
	height: var(--input-height);
	overflow-x: scroll; /* ¯\_(ツ)_/¯ */
	background: white svg-load("../../icons/chevron-down.svg", fill=#a8aa95) right 0.5rem center/1.5rem 1.5rem no-repeat;
	padding: var(--text-baseline-offset) 2.5rem 0 0.75rem;
	text-overflow: ellipsis;
	line-height: 2; /* With insufficient line-height, font ascenders and decenders are clipped. */
}

textarea {
	min-height: calc(2lh + 1.5rem + 2px);
	max-height: calc(10lh + 1.5rem + 2px);
	resize: vertical;
	padding: 0.75rem;
	line-height: 1.25;
}
