.pager {
	animation: var(--animation-fade-in);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 1rem;
}

.pager__label {
	padding-top: var(--text-baseline-offset);
	text-wrap: pretty;
	line-height: 1.25;
	font-size: 0.9375rem;
	color: var(--color-theme-50);
}
