.nav {
	position: sticky;
	top: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	box-shadow: 0 0.125rem 0.25rem rgba(0 0 0/12.5%);
	height: var(--viewport-scroll-padding-top);
	background-color: var(--color-theme-10);
	text-transform: uppercase;
	font-family: "heading", serif;
	letter-spacing: 0.05em;
	color: var(--color-theme-70);

	& > a {
		flex-grow: var(--nav__link-flex-grow, 0);
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--nav__link-background-color, transparent);
		padding-inline: var(--nav__link-padding-inline, 1.5rem);

		&[data-current] {
			--nav__link-background-color: var(--color-theme-30);
			--nav__icon-color: currentColor;
		}

		@media (hover: hover) {
			&:hover {
				--nav__icon-color: currentColor;
			}
		}

		& > svg {
			transition: fill 200ms;
			margin-inline: -0.5rem;
			width: 1.5rem;
			height: 1.5rem;
			fill: var(--nav__icon-color, var(--color-theme-50));
		}

		@media (width < 600px) {
			--nav__link-flex-grow: 1;
			--nav__link-padding-inline: 1rem;
		}

	}

}
