.stock-card {

	--stock-card-padding: 1rem;
	--badge-margin: 1.5rem;

	position: relative;
	margin-top: 1rem;
	margin-inline: calc(var(--stock-card-padding) * -1);
	background-color: white;
	padding: var(--stock-card-padding);
	line-height: 1.35;
}

.stock-card__canvas {
	position: relative;
	box-sizing: content-box;
	width: 100%;
	aspect-ratio: 2/1;

	&:not([data-state~="idle"]) {
		margin-bottom: 1rem;
		border-bottom: 1px solid var(--color-theme-30);
		padding-bottom: 1rem;

		&:empty::before {
			animation: var(--animation-spin);
			position: absolute;
			inset: 50%;
			margin: -1rem;
			width: 2rem;
			height: 2rem;
			border: 3px solid var(--color-signal-neutral);
			border-top-color: transparent;
			border-radius: 50%;
			content: "";
		}

	}

	& > img {
		animation: var(--animation-fade-in);
		vertical-align: top;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

}

.stock-card__label {
	margin-top: 0.25rem;
	text-wrap: pretty;
	line-height: var(--stock-card__label-line-height, inherit);
	font-size: var(--stock-card__label-font-size, inherit);
	color: var(--stock-card__label-color, inherit);

	&.--minor {
		--stock-card__label-line-height: 1.25;
		--stock-card__label-font-size: 0.9375rem;
		--stock-card__label-color: var(--color-theme-70);
	}

}
