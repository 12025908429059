.link {

	--link-gap: 0.25rem;
	--link__icon-width: 1.25rem;

	outline-offset: 0.25rem;
	display: inline-flex;
	align-items: center;
	gap: var(--link-gap);
	line-height: 1.5;
	text-decoration: underline;
	text-underline-offset: 0.375em;
	font-size: var(--link-font-size, inherit);
	color: var(--color-theme-70);

	& > svg {
		margin-left: var(--link__icon-margin-left, 0);
		width: var(--link__icon-width);
		height: 1lh;
		fill: currentColor;

		@media (width >= 720px) {
			.--hanging > & {
				--link__icon-margin-left: calc((var(--link__icon-width) + var(--link-gap)) * -1);
			}
		}

	}

	&.--minor {
		--link-font-size: 0.9375rem;
	}
}
