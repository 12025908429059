form,
.form {

	--form-padding: 2.5rem;
	--form__label-width: 10rem;

	display: flex;
	flex-direction: var(--form-flex-direction, column);
	flex-wrap: var(--form-flex-wrap, nowrap);
	gap: var(--form-gap, 0);
	margin: var(--form-margin, 3.75rem calc(var(--form-padding) * -1) 0);
	background-color: var(--form-background-color, var(--color-theme-10));
	padding: var(--form-padding);

	&.--inline {
		--form-flex-direction: row;
		--form-gap: 1rem;
		--form-margin: 0 auto;
		--form-background-color: transparent;
		--form-padding: 0;
		--form__label-width: auto;
		--form__field-margin-bottom: 0;

		@media (width >= 720px) {
			max-width: 550px;
		}

	}

	&.--wrap {
		flex-wrap: wrap;
	}

	&.--xsmall-labels { --form__label-width: 5rem; }
	&.--small-labels  { --form__label-width: 7.5rem; }
	&.--large-labels  { --form__label-width: 12.5rem; }

	@media (width < 720px) { --form-padding: 1.5rem; }
	@media (width < 500px) { --form-padding: 1rem; }

}

.form__section {
	display: flex;
	gap: 1rem;
	width: 100%;

	&.--columns {

		& > * {
			flex-grow: 1;
			flex-basis: 0;
		};

		@media (width < 500px) {
			flex-direction: column;
		}

	}

}

.form__field {

	--form__field-gap: 0.75rem;

	flex-grow: var(--form__field-flex-grow, 0);
	display: flex;
	flex-direction: var(--form__field-flex-direction, row);
	gap: var(--form__field-gap);
	margin-bottom: var(--form__field-margin-bottom, 1.5rem);
	margin-left: var(--form__field-margin-left, 0);
	width: 100%;

	:is(form, .form)[data-state~="invalid"] &:has(:invalid) {
		--form__label-color: var(--color-signal-failure);
	}

	input,
	select,
	textarea {
		width: 100%;
	}

	&:has(:required) > .form__label {
		padding-right: 1.25ch;

		&::after {
			position: absolute;
			margin-left: 0.25ch;
			font-size: 1.375em;
			font-weight: normal;
			color: var(--color-theme-50);
			content: "*";
		}

	}

	@media (width < 500px) {
		--form__field-flex-direction: column;
		--form__field-gap: 0.25rem;
		--form__label-width: auto;
		--form__label-align-self: flex-start;
		--form__label-padding-top: 0;
	}

}

.form__label {
	transition: color 200ms;
	flex-shrink: 0;
	align-self: var(--form__label-align-self, center);
	width: var(--form__label-width);
	padding-top: var(--form__label-padding-top, var(--text-baseline-offset));
	text-wrap: pretty;
	line-height: 1.25;
	font-weight: 700;
	color: var(--form__label-color, var(--color-theme-70));

	&.--align-top {
		--form__label-align-self: flex-start;
		--form__label-padding-top: 0.5rem;
	}

	&.--inline {
		--form__label-width : auto;
	}

}

.form__footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: var(--form__footer-justify-content, space-between);
	align-items: center;
	gap: 1rem;
	margin-top: 2.5rem;

	&:has(> :only-child, > button:first-child) {
		--form__footer-justify-content: flex-end;
	}

	& > .text {
		animation: var(--animation-fade-in);
		width: 100%;
		border-top: 1px solid var(--color-theme-30);
		padding-top: 1rem;
	}

}
