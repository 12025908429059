/* Fonts: */

@font-face {
	font-family: "heading";
	font-weight: bold;
	font-display: block;
	src: url("/assets/fonts/heldane-display-medium.woff2") format("woff2");
}

@font-face {
	font-family: "text";
	font-weight: 200 1000;
	font-display: block;
	src: url("https://fonts.gstatic.com/s/comfortaa/v45/1Ptsg8LJRfWJmhDAuUs4TYFqL_KWxQ.woff2") format("woff2");
}


/* Colors: */
:root {

	--color-theme-10: #f6f0ec;
	--color-theme-30: #dbd3cd;
	--color-theme-50: #a8aa95;
	--color-theme-70: #50571f;
	--color-theme-90: #1e312a;

	--color-signal-success: #2e8b57;
	--color-signal-neutral: #6495ed;
	--color-signal-warning: #df9711;
	--color-signal-failure: #f15135;

}


/* Utilities: */

:root {
	--viewport-scroll-padding-top: 2.75rem;
	--text-baseline-offset: 0.09375em;
}

/* These need some more specificity: */
:root:root:root {

	.--clear-top    { margin-top: 0; }
	.--clear-bottom { margin-bottom: 0; }
	.--clear-block  { margin-block: 0; }

	.--align-left   { text-align: left; }
	.--align-center { text-align: center; }
	.--align-right  { text-align: right; }

	.--text-nowrap { text-wrap: nowrap; }

}


/* Animations: */

@keyframes spin { to { transform: rotate(1turn); } }

@keyframes fade-in    { from { opacity: 0; } }
@keyframes fade-out   { to   { opacity: 0; } }
@keyframes slide-in   { from { transform: translateY(2.5rem); } }
@keyframes slide-out  { to   { transform: translateY(2.5rem); } }

@keyframes hide { from, to { visibility: hidden; } }

:root {
	--animation-spin:      spin 1s 100ms linear infinite, hide 100ms;
	--animation-fade-in:   fade-in 350ms ease-out;
	--animation-fade-out:  fade-out 350ms ease-in forwards;
	--animation-slide-in:  fade-in 350ms ease-out, slide-in 350ms ease-out;
	--animation-slide-out: fade-out 350ms ease-in forwards, slide-out 350ms ease-in forwards;
}

.--animation-fade-in  { animation: var(--animation-fade-in); }
.--animation-slide-in { animation: var(--animation-slide-in); }


/* Reset: */

*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	border-style: none;
	padding: 0;
	font: inherit;
	color: inherit;
}

li {
	list-style-type: none;
}

a {
	text-decoration: none;
}

[hidden] {
	display: none !important; /* Yes, the nuclear option 🤯 */
}


/* Baseline: */

html {
	overflow-x: hidden;
	overflow-y: var(--viewport-overflow-y, initial);
	scroll-padding-top: var(--viewport-scroll-padding-top);
	scroll-behavior: smooth;
	background-color: var(--color-theme-30);
	line-height: 1;
	text-size-adjust: none;
	font-family: "text", sans-serif;
	color: var(--color-theme-90);
	-webkit-tap-highlight-color: transparent;
}

body {
	scroll-behavior: smooth;
	padding-bottom: 65vh; /* Leaving enough room for dialogs to appear at the bottom of the page without scroll jank. */
}
