.stock-grid {

	--stock-grid-column-count: 3;
	--stock-grid-gap: 1rem;

	display: flex;
	flex-wrap: wrap;
	gap: var(--stock-grid-gap);
	margin-top: 2.5rem;

	& > li {

		--badge-margin: 0.5rem;

		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: calc((100% - ((var(--stock-grid-column-count) - 1) * var(--stock-grid-gap))) / var(--stock-grid-column-count));
		background-color: white;
		padding: 0.5rem;
		line-height: 1.35;

		&[hidden] {
			order: 1;
			display: initial !important; /* Very important, to override the nuclear option 😬 */
			visibility: hidden;
		}

	}

	@media (width < 600px) {
		--stock-grid-column-count: 2;
	}

}

.stock-grid__canvas {
	margin-block: 1.5rem 0.25rem;
	aspect-ratio: 2/1;

	& > img {
		animation: var(--animation-fade-in);
		vertical-align: top;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

}

.stock-grid__label {
	margin-top: 0.25rem;
	text-wrap: pretty;
	line-height: var(--stock-grid__label-line-height, inherit);
	hyphens: auto;
	font-size: var(--stock-grid__label-font-size, 0.9375rem);
	color: var(--stock-grid__label-color, inherit);

	&.--minor {
		--stock-grid__label-line-height: 1.25;
		--stock-grid__label-font-size: 0.875rem;
		--stock-grid__label-color: var(--color-theme-70);
	}

}
