button,
.button {

	--button-border: var(--button-border-width) solid var(--color-theme-90);
	--button-border-width: 2px;
	--button-border-radius: 2.5rem;

	transition: color 200ms;
	outline-offset: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	position: relative;
	min-width: var(--button-min-width, 12.5rem);
	min-height: var(--button-min-height, 2.5rem);
	border: var(--button-border);
	border-radius: var(--button-border-radius);
	background-color: transparent;
	padding-block: var(--button-padding-block, 0);
	padding-left: var(--button-padding-left, 2rem);
	padding-right: var(--button-padding-right, 2rem);
	text-align: center;
	text-transform: uppercase;
	font-family: "heading", serif;
	font-size: var(--button-font-size, inherit);
	letter-spacing: 0.05em;
	color: var(--button-color, currentColor);
	cursor: var(--button-cursor, default);

	&:not(:disabled):active > :is(.button__label, svg) {
		margin: 1px 0 -1px;
	}

	&:disabled {
		--button-color: var(--color-theme-50);
		--button-cursor: not-allowed;
	}

	& > svg {
		transition: color 200ms;
		width: 1.5rem;
		height: 1.5rem;
		fill: currentColor;
	}

	&:has(> input[value]:not(:checked)) {
		--button-color: var(--color-theme-50);
	}

	&:is(
		[data-state~="pending"],
		[data-state~="success"],
		[data-state~="failure"]
	) {

		--button-padding-left: 2.5rem;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			margin: calc(0.5rem - var(--button-border-width));
			width: 1.5rem;
			height: 1.5rem;
			content: "";
		}

	}

	&[data-state~="pending"]::before {
		animation: var(--animation-spin);
		border: 3px solid var(--color-signal-neutral);
		border-top-color: transparent;
		border-radius: 50%;
	}

	&[data-state~="success"]::before {
		border-radius: 50%;
		background: var(--color-signal-success) svg-load("../../icons/check.svg", fill=white) center/80% 80% no-repeat;
	}

	&[data-state~="failure"]::before {
		border-radius: 50%;
		background: var(--color-signal-failure) svg-load("../../icons/error.svg", fill=white) center/80% 80% no-repeat;
	}

	&.--tight {
		--button-min-width: auto;
		--button-padding-left: 1.5rem;
		--button-padding-right: 1.5rem;
	};

	&.--icon {
		--button-min-width: 2.5rem;
		--button-padding-left: 0;
		--button-padding-right: 0;
	}

	&.--dim {
		--button-color: var(--color-theme-50);
	}

	&.--toggle {

		--button-min-width: auto;
		--button-min-height: 2.5rem;
		--button-border-radius: 1.25rem;
		--button-padding-block: 0.625rem;
		--button__label-line-height: 1.25;

		& > input[value]:checked + .button__label::after {
			transform: translate(-50%, -50%) rotate(45deg);
			clip-path: polygon(0 100%, 100% 0, 100% 100%);
			position: absolute;
			top: 100%;
			left: 50%;
			width: 1.25rem;
			height: 1.25rem;
			border: 2px solid currentColor;
			background-color: var(--color-theme-10);
			content: "";
		}

	}

	@media (hover: hover) {
		&:not(:disabled):hover:hover:hover {
			--button-color: currentColor;
		}
	}

	@media (width < 500px) {
		--button-min-width: 10rem;
	}

}

.button__label {
	transition: color 200ms;
	padding-top: var(--text-baseline-offset);
	line-height: var(--button__label-line-height, inherit);
}
