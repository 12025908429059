.badge {
	position: var(--badge-position, absolute);
	top: 0;
	left: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: var(--badge-margin, 0);
	box-shadow: var(--badge-box-shadow, 0 0 0 0.25rem rgb(255 255 255/50%));
	min-width: 2rem;
	height: 1.5rem;
	border: 0.09375rem solid currentColor;
	border-radius: 1.5rem;
	background-color: white;
	padding-top: var(--text-baseline-offset);
	padding-inline: 0.25rem;
	line-height: 1rem;
	font-size: 0.9375rem;
	font-weight: 600;
	color: var(--badge-color, var(--color-theme-70));

	&[data-level="success"] { --badge-color: var(--color-signal-success); }
	&[data-level="neutral"] { --badge-color: var(--color-signal-neutral); }
	&[data-level="warning"] { --badge-color: var(--color-signal-warning); }
	&[data-level="failure"] { --badge-color: var(--color-signal-failure); }

}
