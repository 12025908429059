.heading {
	display: block;
	margin: var(--heading-margin, 0);
	padding-right: 15%;
	line-height: 1.25;
	text-wrap: pretty;
	font-family: "heading", serif;
	font-size: var(--heading-font-size, inherit);
	letter-spacing: 0.0375em;

	&.--level-1 { --heading-margin: 3.75rem 0 1.5rem; --heading-font-size: 1.875rem; }
	&.--level-2 { --heading-margin: 2.5rem 0 1.5rem;  --heading-font-size: 1.5rem; }
	&.--level-3 { --heading-margin: 2.5rem 0 1rem;    --heading-font-size: 1.25rem; }

}
