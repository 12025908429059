table {
	animation: var(--animation-fade-in);
	margin-block: 1.5rem;
	width: 100%;
	border-collapse: collapse;
	border-bottom: var(--table-border-bottom, none);
	font-variant-numeric: tabular-nums;

	&:not(.--clear-bottom):not(.--clear-block) {
		--table-border-bottom: 1px solid var(--color-theme-30);
	}

}

thead {
	position: sticky;
	top: var(--table__header-top, var(--viewport-scroll-padding-top));
	z-index: 1;
	background-color: var(--color-theme-10);
	text-transform: uppercase;
	line-height: 1;
	font-family: "heading", serif;
	letter-spacing: 0.05em;

	&::before {
		position: absolute;
		top: 0;
		z-index: -1;
		margin-left: calc(var(--card-padding-inline) * -1);
		width: calc(100% + (var(--card-padding-inline) * 2));
		height: calc(3rem + 1px);
		background-color: inherit;
		content: "";
	}

	&::after {
		position: absolute;
		top: 3rem;
		margin-left: calc(var(--card-padding-inline) * -1);
		box-shadow: inset 0 0.125rem 0.25rem rgba(0 0 0/12.5%);
		width: calc(100% + (var(--card-padding-inline) * 2));
		height: 0.5rem;
		content: "";
	}

	th,
	td {
		vertical-align: bottom;
		padding: 1rem 0.75rem;
		text-align: left;

		&:first-child { padding-left: 0; }
		&:last-child  { padding-right: 0; }

		&.--icon {
			padding-top: 0;
		}

	}

}

tbody {
	line-height: 1.35;

	tr {
		transition: opacity 200ms ease-in-out;

		&:not(:nth-child(1 of :not([hidden]))) {
			border-top: 1px solid var(--color-theme-30);
		}

		&[data-state~="disabled"] {
			opacity: 0.25;
			cursor: not-allowed;
		}

	}

	&:has(tr[data-state~="open"]) > tr:not([data-state~="open"]) {
		opacity: 0.25;
	}

	th,
	td {
		vertical-align: top;
		padding: 0.75rem;
		text-align: left;

		&:first-child { padding-left: 0; }
		&:last-child  { padding-right: 0; }

	}

}

.table__wrapper {
	width: 100%;
	min-height: var(--wrapper-min-height, none);
}

.table__header {
	position: relative;
	display: block;
	user-select: none;
	cursor: var(--table__header-cursor, auto);

	th:has(> &) {
		--table__header-cursor: pointer;
	}

	/* @todo: Replace this with a single SVG with 2 <use> icons, animate between states. */
	th[data-state~="sorted"] > &::after {
		position: absolute;
		top: 100%;
		left: 0;
		width: 1rem;
		height: 1rem;
		content: "";
		background: center/1.5rem no-repeat;
	}
	th[data-state~="sorted-ascending"]  > &::after { background-image: svg-load("../../icons/chevron-down.svg", fill=#a8aa95); }
	th[data-state~="sorted-descending"] > &::after { background-image: svg-load("../../icons/chevron-up.svg",   fill=#a8aa95); }

	th.--align-center > &::after {
		left: 50%;
		margin-left: -0.5rem;
	}
	th.--align-right > &::after {
		left: auto;
		right: 0;
	}

	& > svg {
		vertical-align: top;
		margin: -0.25rem;
		width: 1.5rem;
		height: 1.5rem;
		line-height: 1rem;
		fill: currentColor;
	}

}

.table__icon {
	transition: color 200ms;
	padding: 0.625rem 0;
	color: var(--table__icon-color, var(--color-theme-50));

	& > svg {
		margin-inline: var(--table__icon-margin-inline, 0);
		width: 1.5rem;
		height: 1.5rem;
		fill: currentColor;
	}

	&.--tight {
		--table__icon-margin-inline: -0.25rem;
	}

	@media (hover: hover) {
		tr:hover & {
			--table__icon-color: currentColor;
		}
	}

}

.table__label {
	margin-top: 0.25rem;
	text-wrap: pretty;
	line-height: var(--table__label-line-height, inherit);
	font-size: var(--table__label-font-size, inherit);
	color: var(--table__label-color, inherit);

	&.--minor {
		--table__label-line-height: 1.25;
		--table__label-font-size: 0.9375rem;
		--table__label-color: var(--color-theme-70);
	}

}
