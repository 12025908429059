.card {

	--card-max-width: 1200px;
	--card-padding-block: 2.5rem;
	--card-padding-inline: 2.5rem;

	position: relative;
	margin-top: 3.75rem;
	margin-inline: var(--card-margin-inline, calc(var(--card-padding-inline) * -1));
	max-width: var(--card-max-width);
	background-color: var(--color-theme-10);
	padding-block: var(--card-padding-block);
	padding-inline: var(--card-padding-inline);

	&.--wide {

		@media (width >= 720px) {
			--card-margin-inline: calc(((min(var(--card-max-width), 100vw) - 100%) / -2) + 1.5rem);
		}

	}

	@media (width < 720px) {
		--card-padding-block: 1.5rem;
		--card-padding-inline: 1.5rem;
	}

	@media (width < 500px) {
		--card-padding-inline: 1rem;
	}

}
