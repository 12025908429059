.header {
	display: flex;
	justify-content: center;
	background: var(--color-theme-70) url("/assets/images/header.webp") top/cover no-repeat;
	padding-block: 1.5rem;
}

.header__logo {
	outline-offset: 0.5rem;
	height: 3.25rem;

	& > img {
		vertical-align: top;
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

}
