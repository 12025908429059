.button-group {
	display: inline-flex;
	justify-content: center;
	margin-bottom: 2.5rem;

	& > :is(button, .button) {
		outline-offset: -1px;
		border-inline-style: none;
		border-radius: 0;

		&:first-child {
			border-left: var(--button-border);
			border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
		}
		&:last-child {
			border-right: var(--button-border);
			border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;
		}

		&:not(:focus-visible) + &:not(:focus-visible)::before {

			--button-group__divider-margin-block: 0.375rem;

			position: absolute;
			top: 0;
			left: 0;
			margin-block: var(--button-group__divider-margin-block);
			height: calc(100% - (var(--button-group__divider-margin-block) * 2));
			border-left: 1px solid var(--color-theme-30);
			content: "";
		}

	}

}
